<template>
  <a-card :bordered="false" size="small" style="height: 240px">
    <template slot="title">库存预警</template>
    <div style="height: 179px; overflow: auto; font-size: 16px; padding: 0 16px;">
      <a-list size="small" :data-source="dataItems">
        <a-list-item slot="renderItem" slot-scope="item, index">
          <a-space>
            <span>{{item.category}}</span>
            <span>{{ `( ${item.count} 条预警物资 )` }}</span>
          </a-space>
        </a-list-item>
      </a-list>
    </div>
  </a-card>
</template>

<script>
import { inventoryWarning } from "@/api/stats";

export default {
  data() {
    return {
      dataItems: [],
    };
  },
  methods: {
    initData() {
      this.getData();
    },
    getData() {
      inventoryWarning().then((data) => {
        this.dataItems = data;
      });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
